<template>
  <div class="row mb-3">
    <div class="col-sm-12 col-md-4 col-lg-3">
      <Folder
        :items="files"
        @add-folder="$refs.FormFolderModal.toggleModal()"
        style="h-100"
      />
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
      <div class="card rounded h-100">
        <div class="card-body">
          <Breadcrumbs @add-document="$refs.FormDocumentModal.toggleModal()" />
          <div class="d-flex justify-content-between align-items-center mt-5">
            <div>
              <b-form-input
                id="input-small"
                size="sm"
                v-model="search"
                placeholder="Type to search...."
              ></b-form-input>
            </div>
            <div class="d-flex gap-2">
              <div
                @click="view_type = 'table'"
                :class="[
                  'container p-1 view_type rounded',
                  view_type == 'table' ? 'active-background' : '',
                ]"
                role="button"
              >
                <inline-svg
                  src="media/svg/icons/Text/Bullet-list.svg"
                  style="color: #aaaaaa"
                />
              </div>
              <div
                @click="view_type = 'grid'"
                :class="[
                  'container p-1 view_type rounded',
                  view_type == 'grid' ? 'active-background' : '',
                ]"
                role="button"
              >
                <inline-svg
                  src="media/svg/icons/Layout/Layout-grid.svg"
                  style="color: #aaaaaa"
                />
              </div>
            </div>
          </div>

          <TableView
            @delete="deleteItem"
            @edit="editItem"
            @archive="archiveItem"
            :items="items"
            v-if="view_type == 'table'"
          />
          <GridView
            @edit="editItem"
            @delete="deleteItem"
            @archive="archiveItem"
            :items="items"
            v-else
          />
        </div>
      </div>
    </div>
    <FormFolderModal ref="FormFolderModal" />
    <FormDocumentModal ref="FormDocumentModal" />
    <FormArchive ref="FormArchive" @hide-arhive="getFolder" />
    <b-modal
      ref="delete-modal"
      id="delete-modal"
      size="md"
      centered
      hide-footer
      hide-header
    >
      <div class="d-flex justify-content-center">
        <div class="text-center">
          <h3>Hapus Data</h3>
          <p class="mb-10">
            Apakah Anda yakin menghapus {{ selected.type || "" }} “{{
              selected.name || ""
            }}”?
          </p>
          <div class="d-flex justify-content-center" style="gap: 1rem">
            <button
              class="btn btn-light"
              @click="$bvModal.hide('delete-modal')"
            >
              Batalkan
            </button>
            <button class="btn btn-info" @click="deleteFolder">
              Ya, Hapus Data
            </button>
          </div>
        </div>
      </div>
      <p></p>
    </b-modal>
    <preview-pdf />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_FOLDER,
  DELETE_FOLDER,
  SET_SELECTED_FOLDER,
  SEARCH_DOCUMENT,
} from "@/core/services/store/file.module";
import Folder from "@/view/pages/file_management/components/Folder.vue";
import TableView from "@/view/pages/file_management/TableView.vue";
import GridView from "@/view/pages/file_management/GridView.vue";

import { fileManagementMixin } from "@/view/pages/file_management/mixin/fileManagementMixin";

//modal
import FormFolderModal from "@/view/pages/file_management/modal/FormFolder.vue";
import FormDocumentModal from "@/view/pages/file_management/modal/FormDocument.vue";
import FormArchive from "@/view/pages/file_management/modal/FormArchive.vue";

import Breadcrumbs from "@/view/pages/file_management/components/Breadcrumbs.vue";
import PreviewPdf from "@/view/pages/file_management/components/PreviewPdf.vue";

import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "FileManagement",
  mixins: [fileManagementMixin],
  components: {
    Folder,
    TableView,
    GridView,
    FormFolderModal,
    FormDocumentModal,
    Breadcrumbs,
    PreviewPdf,
    FormArchive,
  },
  computed: {
    ...mapGetters(["currentSelectedFolder", "currentUser"]),
    isArchive() {
      return this.$route.query.type && this.$route.query.type == "archive";
    },
    files() {
      return this.$store.getters.currentFiles;
    },
    items: function () {
      return this.$store.getters.currentDisplayFolder;
    },
  },
  data: () => {
    return {
      view_type: "grid",
      folderSelected: "root",
      selected: {},
      search: "",
    };
  },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Dokumen" }]);
    this.getFolder();
  },
  watch: {
    isArchive: function (newVal) {
      this.getFolder();
    },
    search: function (newVal) {
      this.$store.dispatch(SEARCH_DOCUMENT, newVal);
    },
  },
  methods: {
    deleteItem(item) {
      this.$refs["delete-modal"].toggle();
      this.selected = item;
    },
    archiveItem(item) {
      this.$refs.FormArchive.toggleModal(item);
    },
    editItem(item) {
      if (item.type == "folder") {
        this.$refs.FormFolderModal.toggleModal(item);
      } else {
        this.$refs.FormDocumentModal.toggleModal(item);
      }
    },
    getFolder() {
      this.$store
        .dispatch(GET_FOLDER, this.isArchive ? "archive" : null)
        .then((response) => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteFolder() {
      this.$store
        .dispatch(DELETE_FOLDER, this.selected._id)
        .then((response) => {
          this.$refs["delete-modal"].toggle();
          this.isLoading = false;

          Swal.fire({
            title: response.message,
            text: `Berhasil menghapus`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });

          this.getFolder();
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast(response, {
            title: `Gagal menghapus folder`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped>
.view_type:hover {
  background-color: #f2f2f2;
}

.active-background {
  background-color: #f2f2f2;
  color: #ffc300;
}
</style>
