<template>
  <b-modal
    ref="archive-modal"
    id="archive-modal"
    size="md"
    centered
    hide-footer
    hide-header
    @hide="$emit('hide-arhive')"
  >
    <div class="d-flex justify-content-center">
      <div class="text-center">
        <h3>{{ selected.is_archive ? "Pulihkan" : "Arsipkan" }} Data</h3>
        <p class="mb-10">
          Apakah Anda yakin
          {{ selected.is_archive ? "memulihkan" : "meng-arsipkan" }}
          {{ selected.type || "" }} “{{ selected.name || "" }}”?
        </p>
        <div class="d-flex justify-content-center" style="gap: 1rem">
          <button
            class="btn btn-light"
            @click="$bvModal.hide('archive-modal')"
            :disabled="isLoading"
          >
            Batalkan
          </button>
          <button
            class="btn btn-info"
            @click="changeStatusArchive"
            :disabled="isLoading"
          >
            {{
              isLoading
                ? "Sedang Meng-arsipkan"
                : selected.is_archive
                ? "Ya, Pulihkan Data"
                : "Ya, Arsipkan Data"
            }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { UPDATE_DOCUMENT_ARCHIVE } from "@/core/services/store/file.module";
import Swal from "sweetalert2";

export default {
  name: "archive",
  data: () => {
    return {
      selected: {},
      isLoading: false,
    };
  },
  methods: {
    toggleModal(item) {
      this.$refs["archive-modal"].toggle();
      this.selected = item;
    },
    changeStatusArchive(event) {
      this.isLoading = true;

      var is_archive = this.selected.is_archive || false;

      const _payload = {
        _id: this.selected._id,
        is_archive: !is_archive,
      };

      this.$store
        .dispatch(UPDATE_DOCUMENT_ARCHIVE, _payload)
        .then((response) => {
          this.isLoading = false;
          Swal.fire({
            title: response.message,
            text: `Berhasil ${
              this.selected.is_archive ? "memulihkan" : "mengarsipkan"
            } Dokumen`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then((x) => {
            this.$refs["archive-modal"].toggle();
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$bvToast
            .toast(error, {
              title: `Gagal ${
                this.id ? "meng-arsipkan" : "memulihkan"
              } Dokumen`,
              variant: "danger",
              solid: true,
            })
            .then((x) => {
              this.$refs["archive-modal"].toggle();
            });
        });
    },
  },
};
</script>
