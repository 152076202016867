<template>
  <div>
    <b-table
      responsive
      hover
      fixed
      :fields="fields"
      :items="items"
      class="mt-5"
      head-variant="light"
      show-empty
      @row-clicked="onRowClicked"
    >
      <template #table-colgroup="scope">
        <col
          class="td-click"
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'action' ? '50px' : '180px',
          }"
        />
      </template>
      <template #empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4>{{ scope.emptyFilteredText }}</h4>
      </template>
      <template #cell(name)="data">
        <span v-if="data.item.type == 'file'">{{
          data.item.name
            ? `${data.item.name.replaceAll(`.${data.item.ext}`, "")}.${
                data.item.ext
              }`
            : "-"
        }}</span>
        <span v-else>{{ data.item.name }}</span>
      </template>
      <template #cell(document_number)="data">
        <span>{{ data.item.document_number ?? "-" }}</span>
      </template>
      <template #cell(action)="data">
        <b-dropdown
          variant="light"
          toggle-class="custom-v-dropdown btn btn-hover-light-primary btn-icon bg-transparent border-0"
          no-caret
          right
          no-flip
        >
          <template v-slot:button-content>
            <i class="ki ki-bold-more-ver"></i>
          </template>
          <!--begin::Navigation-->
          <b-dropdown-text
            tag="div"
            @click="open(data.item)"
            role="button"
            class="navi-item mb-3 mt-1"
          >
            <span class="navi-link cursor-pointer">
              <span class="navi-text">Buka</span>
            </span>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item mb-3" v-if="!isEmployee">
            <span class="navi-link cursor-pointer">
              <span class="navi-text" @click="$emit('edit', data.item)"
                >{{ data.item.type == "file" ? "Edit" : "Edit" }}
              </span>
            </span>
          </b-dropdown-text>
          <b-dropdown-text
            v-if="!isEmployee && data.item.type == 'file'"
            tag="div"
            class="navi-item mb-3"
            @click="$emit('archive', data.item)"
          >
            <span class="navi-link cursor-pointer">
              <span class="navi-text">{{
                !isArchive ? "Arsipkan" : "Pulihkan"
              }}</span>
            </span>
          </b-dropdown-text>
          <b-dropdown-text
            v-if="!isEmployee"
            tag="div"
            class="navi-item"
            @click="$emit('delete', data.item)"
          >
            <span class="navi-link cursor-pointer">
              <span class="navi-text text-danger">Hapus</span>
            </span>
          </b-dropdown-text>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import { fileManagementMixin } from "@/view/pages/file_management/mixin/fileManagementMixin.js";
import {
  SET_SELECTED_FOLDER,
  OPEN_PREVIEW_PDF,
} from "@/core/services/store/file.module";
export default {
  name: "TableView",
  mixins: [fileManagementMixin],
  props: ["items"],
  data: () => {
    return {
      view_type: "table",
      fields: [
        {
          key: "name",
          label: "Nama",
          tdClass: "td-click",
          sortable: true,
        },
        {
          key: "document_number",
          label: "Nomor Dokumen",
          tdClass: "td-click",
        },
        {
          key: "last_updated",
          label: "Tanggal Dokumen",
          tdClass: "td-click",
          sortable: true,
        },
        {
          key: "uploaded_at",
          label: "Tanggal Upload",
          tdClass: "td-click",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },
  computed: {
    isEmployee() {
      return (
        this.$store.getters.currentUser.role == "student" ||
        this.$store.getters.currentUser.role == "staff"
      );
    },
    isArchive() {
      return this.$route.query.type && this.$route.query.type == "archive";
    },
  },
  methods: {
    open(item) {
      if (item.type == "folder")
        this.$store.dispatch(SET_SELECTED_FOLDER, item);
      else {
        this.$store.dispatch(OPEN_PREVIEW_PDF, item);
      }
    },
    onRowClicked(item, index, event) {
      this.open(item);
    },
  },
};
</script>
<style>
.td-click {
  cursor: pointer !important;
}
</style>
