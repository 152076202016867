<template>
  <div>
    <template v-if="item.child !== undefined">
      <div
        v-for="child in item.child.filter((x) => x.type == 'folder')"
        :key="child._id"
      >
        <div
          role="button"
          @click="open(child)"
          class="d-flex justify-content-start align-items-center py-3"
          block
          v-b-toggle="'accordion-' + child._id"
          style="gap: 1rem"
        >
          <i class="fa fa-folder fa-xl"></i>
          <span
            :class="[
              'ml-1',
              currentSelectedFolder._id == child._id ? 'text-warning' : '',
            ]"
          >
            {{ child.name }}
          </span>
        </div>
        <b-collapse
          v-if="child.child !== undefined"
          :id="'accordion-' + child._id"
          :accordion="'my-accordion-1' + child._id"
          role="tabpanel"
          class="ml-5"
        >
          <ChildFolder :item="child" />
        </b-collapse>
      </div>
    </template>
  </div>
</template>

<script>
import { fileManagementMixin } from "@/view/pages/file_management/mixin/fileManagementMixin";
import { SET_SELECTED_FOLDER } from "@/core/services/store/file.module";

import { mapGetters } from "vuex";

export default {
  name: "ChildFolder",
  mixins: [fileManagementMixin],
  props: {
    item: Object,
  },
  components: {
    ChildFolder: this,
  },
  computed: {
    ...mapGetters(["currentSelectedFolder"]),
  },
  methods: {
    open(item) {
      this.$store.dispatch(SET_SELECTED_FOLDER, item);
    },
  },
};
</script>
