var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['d-flex align-items-center', _vm.css],attrs:{"role":"button"},on:{"click":_vm.checkBoxClick}},[_c('div',{staticClass:"border rounded custom-checkbox mr-2 d-flex align-items-center",class:{
      'bg-info': _vm.isSelected,
      'bg-white': !_vm.isSelected,
    }},[(_vm.isSelected)?_c('i',{staticClass:"fa fa-check mx-auto fa-sm",class:{
        'text-white': _vm.isSelected,
      }}):_vm._e(),(!_vm.isSelected)?_c('i',{staticClass:"fa fa-minus mx-auto fa-sm",class:{
        'text-white': _vm.isSelected,
      }}):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }