<template>
  <div class="row gx-2 justify-content-between align-items-center gy-sm-5">
    <div class="ml-3 row gap-2">
      <strong
        role="button"
        @click="open({ parent_id: null, name: 'root', _id: null })"
        >Dokumen {{ isArchive ? "Arsip" : "" }} Grafindo</strong
      >
      <span
        role="button"
        @click="open(item)"
        v-for="item in breadcrumbs"
        :key="item._id"
      >
        <i
          class="fa fa-chevron-right ml-2 mr-1"
          style="color: black; font-size: 1rem"
        ></i>
        {{ item.name }}
      </span>
    </div>
    <b-button
      v-if="!isEmployee && !isArchive"
      @click="$emit('add-document')"
      variant="info"
      ><i class="fa fa-file mr-3"></i> Tambah File</b-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_SELECTED_FOLDER } from "@/core/services/store/file.module";
export default {
  name: "FileManagementBreadcrumbs",
  data() {
    return {
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapGetters(["currentSelectedFolder", "currentAllFiles"]),
    currentSelectedFolder() {
      return this.$store.getters.currentSelectedFolder;
    },
    isEmployee() {
      return (
        this.$store.getters.currentUser.role == "student" ||
        this.$store.getters.currentUser.role == "staff"
      );
    },
    isArchive() {
      return this.$route.query.type && this.$route.query.type == "archive";
    },
  },
  watch: {
    currentSelectedFolder: function (oldVal, newVal) {
      this.breadcrumbs = [];
      this.getCurrentList(this.currentAllFiles, this.currentSelectedFolder._id);
    },
  },
  methods: {
    open(item) {
      this.$store.dispatch(SET_SELECTED_FOLDER, item);
    },
    getCurrentList(data, item) {
      data.map((x) => {
        if (x._id == item) {
          this.breadcrumbs.unshift(x);
          if (x.parent_id) {
            this.getCurrentList(data, x.parent_id);
          }
        }
      });
    },
  },
};
</script>
