<!-- eslint-disable -->
<template>
  <b-modal
    :title="title"
    ref="preview-modal"
    id="preview-modal"
    size="xl"
    centered
    hide-footer
    hide-header
    @hide="hideModal"
    @show="showModal"
    @contextmenu.prevent="disableContextMenu"
  >
    <div
      class="d-flex justify-content-center align-items-center"
      style="gap: 1rem"
      v-if="isLoading"
    >
      <b-spinner type="grow" label="Spinning"></b-spinner> sedang menyiapkan
      pdf.
    </div>
    <template v-else>
      <!-- <pdf
        src="test-pdf.pdf"
        :page="page"
        @num-pages="numPages = $event"
        style="width: 75%"
      ></pdf> -->
      <!-- <pdf
        v-if="pdfData"
        ref="myPdfComponent"
        style="width: 75%"
        :src="pdfData"
      ></pdf> -->
      <!-- <iframe
        id="previewPdf"
        ref="framePreviewPDf"
        :src="`${pdfUrl}#toolbar=0`"
        width="100%"
        height="700"
        frameborder="0"
        @contextmenu.prevent="disableContextMenu"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe> -->
      <div>
        <div class="d-flex gap-3 mb-3">
          <b-icon
            role="button"
            icon="zoom-in"
            font-scale="2"
            @click="zoomIn"
          ></b-icon>
          &nbsp;&nbsp;
          <b-icon
            role="button"
            icon="zoom-out"
            font-scale="2"
            @click="zoomOut"
          ></b-icon>
        </div>
        <div class="d-flex justify-content-center w-100">
          <div
            id="pdfViewer"
            style="
              max-height: 70vh;
              width: 100%;
              overflow: auto;
              text-align: center;
            "
          ></div>
        </div>
      </div>
    </template>

    <div class="d-flex justify-content-end" style="gap: 1rem">
      <!-- <button class="btn bt  n-info"><i class="fa fa-download"></i> Unduh</button> -->
    </div>
  </b-modal>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.min.mjs"></script>

<script>
import { mapGetters } from "vuex";

import {
  OPEN_PREVIEW_PDF,
  GET_DOCUMENT,
} from "@/core/services/store/file.module";
export default {
  name: "PreviewPDf",
  components: {},
  computed: {
    ...mapGetters(["currentPreviewPDF"]),
    title() {
      return this.currentPreviewPDF?.name !== undefined
        ? this.currentPreviewPDF.name
        : "";
    },
  },
  beforeMount() {
    document.contextmenu = (e) => {};
  },
  async mounted() {},
  data() {
    return {
      isLoading: false,
      pdfUrl: null,
      password: "pke",
      page: 0,
      pdfData: null,

      pdfDoc: null,
      pageNum: 1,
      numPages: 0,
      scale: 1.0,
    };
  },
  watch: {
    currentPreviewPDF: function (newVal) {
      if (newVal) {
        this.$refs["preview-modal"].toggle();
        this.getDocument();
      }
    },
    isLoading: function (newVal) {
      if (!newVal) {
        window.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });
      }
    },
  },
  methods: {
    hideModal() {
      this.$store.dispatch(OPEN_PREVIEW_PDF, null);
    },
    getDocument() {
      this.isLoading = true;
      this.pdfData = null;
      this.$store
        .dispatch(GET_DOCUMENT, this.currentPreviewPDF._id)
        .then((response) => {
          let data = response.data.data;

          let buffer = new Uint8Array(data.blob.data);

          const blob = new Blob([buffer], {
            type: "application/pdf",
          });

          this.loadPDF(window.URL.createObjectURL(blob), data.password);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$bvToast.toast(error, {
            title: `Gagal Memuat Document`,
            variant: "danger",
            solid: true,
          });
        });
    },
    showModal() {
      const pdf = this.$refs.framePreviewPDf;
    },
    disableContextMenu(event) {
      event.preventDefault();
    },
    onErrorPreviewPDf(error) {},
    onPageLoaded() {},
    onNumPages(nums) {},
    readPdfBlob(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.loadPdf(reader.result);
      };
      reader.readAsArrayBuffer(file);
    },
    loadPDF(file, password = "") {
      this.numPages = 0;
      this.pageNum = 1;

      pdfjsLib
        .getDocument({
          url: file,
          password: this.getPassword(password),
        })
        .promise.then((pdfDocument) => {
          this.pdfDoc = pdfDocument;
          // this.renderPage(this.pageNum);
          this.updatePageNumber();
          this.renderPageFull();
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    renderPage(num) {
      this.pdfDoc.getPage(num).then((page) => {
        let container = document.getElementById("pdfViewer");

        let viewport = page.getViewport({ scale: 1 });
        let scale = container.clientWidth / viewport.width;

        viewport = page.getViewport({ scale: scale });

        for (var i = this.numPages; i > this.pageNum; i--) {}

        const canvas = document.createElement("canvas");
        document.getElementById("pdfViewer").innerHTML = "";
        document.getElementById("pdfViewer").appendChild(canvas);

        const context = canvas.getContext("2d");

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const outputScale = window.devicePixelRatio || 1;
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        context.setTransform(outputScale, 0, 0, outputScale, 0, 0);

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext);
      });
    },
    renderPageFull() {
      for (var i = 1; i <= this.numPages; i++) {
        var num = i;
        this.pdfDoc.getPage(num).then((page) => {
          let scale = this.scale;
          var viewport = page.getViewport({ scale: scale });
          let container = document.getElementById("pdfViewer");
          // Support HiDPI-screens.
          var outputScale = window.devicePixelRatio || 1;

          const canvas = document.createElement("canvas");
          container.append(canvas);

          const context = canvas.getContext("2d");

          canvas.width = Math.floor(viewport.width * outputScale);
          canvas.height = Math.floor(viewport.height * outputScale);
          canvas.style.width = Math.floor(viewport.width) + "px";
          canvas.style.height = Math.floor(viewport.height) + "px";

          var transform =
            outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

          var renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport,
          };

          page.render(renderContext);
        });
      }
    },
    updatePageNumber() {
      this.numPages = this.pdfDoc.numPages;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loadPDF(URL.createObjectURL(file));
      }
    },
    prevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.renderPage(this.pageNum);
    },
    nextPage() {
      if (this.pageNum >= this.numPages) {
        return;
      }
      this.pageNum++;
      this.renderPage(this.pageNum);
    },
    getPassword(encrypted) {
      const CryptoJS = require("crypto-js");

      var secret = process.env.VUE_APP_PDF_SECRET_KEY;
      const bytes = CryptoJS.AES.decrypt(encrypted, secret);
      return bytes.toString(CryptoJS.enc.Utf8);
    },
    zoomOut() {
      const canvas = document.createElement("canvas");
      document.getElementById("pdfViewer").innerHTML = "";

      this.scale = this.scale - 0.25;
      this.renderPageFull();
    },
    zoomIn() {
      const canvas = document.createElement("canvas");
      document.getElementById("pdfViewer").innerHTML = "";

      this.scale = this.scale + 0.25;
      this.renderPageFull();
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0px !important;
}
</style>
