<template>
  <b-modal
    ref="form-document-modal"
    id="form-document-modal"
    size="xl"
    :title="`${isEdit ? 'Edit' : 'Tambah'} Dokumen`"
    centered
    lazy
    static
    no-close-on-backdrop
    @hide="hideModal"
  >
    <validation-observer ref="formDocument">
      <b-form @submit.prevent="submitForm">
        <b-form-group
          id="input-group-1"
          label="Induk Folder"
          label-for="input-1"
        >
          <validation-provider
            #default="{ errors }"
            name="Induk Folder"
            rules="required"
          >
            <b-form-input
              :state="errors.length > 0 ? false : null"
              id="input-1"
              type="text"
              disabled
              v-model="form.parent.name"
              placeholder="Induk Folder"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Nama Dokumen"
          label-for="input-1"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Dokumen"
            rules="required"
          >
            <b-form-input
              :state="errors.length > 0 ? false : null"
              id="input-1"
              v-model="form.name"
              type="text"
              placeholder="Nama Dokumen"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Nomor Dokumen">
          <validation-provider
            #default="{ errors }"
            name="Nomor Dokumen"
            rules="required"
          >
            <b-form-input
              :state="errors.length > 0 ? false : null"
              id="input-1"
              v-model="form.document_number"
              type="text"
              placeholder="Nomor Dokumen"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Tanggal Dokumen">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Dokumen"
            rules="required"
          >
            <b-form-datepicker
              v-model="form.document_date"
              class="mb-2"
              :date-format-options="{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Unggah Dokumen"
          label-for="input-3"
          v-if="!isEdit"
        >
          <validation-provider
            #default="{ errors }"
            name="Unggah Dokumen"
            rules="required"
          >
            <b-form-file
              ref="file"
              :state="errors.length > 0 ? false : null"
              placeholder="Pilih File..."
              v-model="form.file"
              drop-placeholder="Pilih File..."
              accept="application/pdf"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <PilihHakAkses
        css="shadow-none"
        :initValue="form.access"
        @selected="getHakAkses"
      />
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        type="button"
        size="sm"
        variant="outline-secondary"
        @click="
          () => {
            cancel();
          }
        "
        >Batalkan
      </b-button>
      <b-button
        type="button"
        size="sm"
        variant="success"
        @click="submitForm(ok)"
        class="d-flex align-items-center"
        style="gap: 1rem"
      >
        <template v-if="currentIsLoadingSubmit">
          <b-spinner small variant="primary" label="Spinning"></b-spinner>
          {{ currentSubmitProgress }}
        </template>
        <template v-else> Simpan Data </template>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PilihHakAkses from "@/view/pages/hak-akses/PilihHakAkses.vue";
import {
  UPLOAD_DOCUMENT,
  GET_FOLDER,
  UPDATE_DOCUMENT_ACCESS,
} from "@/core/services/store/file.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "FormFolderModal",
  components: {
    PilihHakAkses,
  },
  data() {
    return {
      form: {
        name: null,
        file: null,
        document_number: null,
        document_date: null,
        parent: {
          parent_id: null,
          name: "Dokumen Grafindo",
        },
        type: "file",
        access: [],
      },
    };
  },
  watch: {
    "form.file": function (newVal) {
      if (!this.id && newVal?.name !== undefined && !this.form.name) {
        var fileName = newVal.name.split(".");
        if (fileName.length > 0) {
          fileName.pop(fileName.length - 1);
          fileName = fileName.join(".");
        } else {
          fileName = newVal;
        }

        this.form.name = fileName;
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentIsLoadingSubmit",
      "currentSubmitProgress",
      "currentSelectedFolder",
      "currentFiles",
    ]),
    isEdit() {
      return this.form._id !== undefined;
    },
    id() {
      return this.form._id;
    },
  },
  methods: {
    toggleModal(item) {
      if (item !== undefined) {
        this.form = {
          ...item,
        };

        if (item.parent_id) {
          this.setFolderParent(
            this.currentSelectedFolder._id,
            this.currentFiles
          );
        } else {
          this.form.parent = {
            name: "Dokumen Grafindo",
            parent_id: null,
          };
        }
      } else {
        if (this.currentSelectedFolder._id != null) {
          this.setFolderParent(
            this.currentSelectedFolder._id,
            this.currentFiles
          );
        } else {
          this.form.parent = {
            name: "Dokumen Grafindo",
            parent_id: null,
          };
        }
      }

      this.$refs["form-document-modal"].toggle();
    },
    submitForm(event) {
      this.$refs.formDocument.validate().then((success) => {
        if (!success) return;
        var _data = this.form;
        _data.parent_id = this.form.parent.parent_id;
        this.$store
          .dispatch(this.id ? UPDATE_DOCUMENT_ACCESS : UPLOAD_DOCUMENT, _data)
          .then((response) => {
            Swal.fire({
              title: response.message,
              text: `Berhasil ${this.id ? "mengubah" : "membuat"} Dokumen`,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            }).then(() => {
              this.getFolder();

              event();
            });
          })
          .catch((error) => {
            this.$bvToast.toast(error?.data?.message ?? "terjadi kesalahan", {
              title: `Gagal ${this.id ? "mengubah" : "membuat"} Dokumen`,
              variant: "danger",
              solid: true,
            });
          });
      });
    },
    setFolderParent(id, _data) {
      _data.map((x) => {
        if (x._id == id) {
          this.form.parent = {
            parent_id: x._id,
            name: x.name,
          };
          return;
        }
        if (x.child) this.setFolderParent(id, x.child);
      });
    },
    getFolder() {
      this.$store
        .dispatch(GET_FOLDER)
        .then((response) => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    hideModal() {
      this.form = {
        name: null,
        parent: {
          parent_id: null,
          name: "Dokumen Grafindo",
        },
        type: "folder",
        access: [],
      };
    },
    getHakAkses(item) {
      this.form.access = item;
    },
  },
};
</script>
