var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-3"},[_c('Folder',{staticStyle:{},attrs:{"items":_vm.files},on:{"add-folder":function($event){return _vm.$refs.FormFolderModal.toggleModal()}}})],1),_c('div',{staticClass:"col-sm-12 col-md-8 col-lg-9"},[_c('div',{staticClass:"card rounded h-100"},[_c('div',{staticClass:"card-body"},[_c('Breadcrumbs',{on:{"add-document":function($event){return _vm.$refs.FormDocumentModal.toggleModal()}}}),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-5"},[_c('div',[_c('b-form-input',{attrs:{"id":"input-small","size":"sm","placeholder":"Type to search...."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex gap-2"},[_c('div',{class:[
                'container p-1 view_type rounded',
                _vm.view_type == 'table' ? 'active-background' : '',
              ],attrs:{"role":"button"},on:{"click":function($event){_vm.view_type = 'table'}}},[_c('inline-svg',{staticStyle:{"color":"#aaaaaa"},attrs:{"src":"media/svg/icons/Text/Bullet-list.svg"}})],1),_c('div',{class:[
                'container p-1 view_type rounded',
                _vm.view_type == 'grid' ? 'active-background' : '',
              ],attrs:{"role":"button"},on:{"click":function($event){_vm.view_type = 'grid'}}},[_c('inline-svg',{staticStyle:{"color":"#aaaaaa"},attrs:{"src":"media/svg/icons/Layout/Layout-grid.svg"}})],1)])]),(_vm.view_type == 'table')?_c('TableView',{attrs:{"items":_vm.items},on:{"delete":_vm.deleteItem,"edit":_vm.editItem,"archive":_vm.archiveItem}}):_c('GridView',{attrs:{"items":_vm.items},on:{"edit":_vm.editItem,"delete":_vm.deleteItem,"archive":_vm.archiveItem}})],1)])]),_c('FormFolderModal',{ref:"FormFolderModal"}),_c('FormDocumentModal',{ref:"FormDocumentModal"}),_c('FormArchive',{ref:"FormArchive",on:{"hide-arhive":_vm.getFolder}}),_c('b-modal',{ref:"delete-modal",attrs:{"id":"delete-modal","size":"md","centered":"","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Hapus Data")]),_c('p',{staticClass:"mb-10"},[_vm._v(" Apakah Anda yakin menghapus "+_vm._s(_vm.selected.type || "")+" “"+_vm._s(_vm.selected.name || "")+"”? ")]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"1rem"}},[_c('button',{staticClass:"btn btn-light",on:{"click":function($event){return _vm.$bvModal.hide('delete-modal')}}},[_vm._v(" Batalkan ")]),_c('button',{staticClass:"btn btn-info",on:{"click":_vm.deleteFolder}},[_vm._v(" Ya, Hapus Data ")])])])]),_c('p')]),_c('preview-pdf')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }