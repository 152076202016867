export const fileManagementMixin = {
    data() {
        return {
            deleteModal : false,
            folderSelected: 'root',
        }
    },
    methods: {
       openModalDelete() {
        this.deleteModal = !this.deleteModal;
       },
       openFolder(folerName) {
        this.folderSelected = folerName;
       }
    },
};