var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom card-stretch"},[_vm._m(0),_c('b-card-body',[(!_vm.isLoading)?[_c('div',{staticClass:"row"},_vm._l((_vm.level),function(levelNumber){return _c('div',{key:levelNumber,staticClass:"col-lg-4 col-md-6 col-sm-12 mb-3"},[(_vm.allLevel.filter((x) => x.level == levelNumber).length > 0)?[_c('span',{staticClass:"text-dark font-weight-bold text-hover-primary font-size-sm mr-2"},[_vm._v(_vm._s(_vm.levelName[levelNumber - 1]))]),_c('b-card',{attrs:{"no-body":""}},[_c('div',[(levelNumber > 1)?[_vm._l((_vm.allLevel.filter(
                        (x) => x.level == levelNumber - 1
                      )),function(parent){return [(
                          _vm.allLevel.filter((x) => x.parent_id == parent._id)
                            .length > 0
                        )?[_c('div',{key:parent._id,staticClass:"d-flex justify-content-between align-items-center bg-secondary px-3 py-1"},[_c('span',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(parent.name)+" "+_vm._s(parent.level == 4 ? ` - ${ _vm.allLevel.filter( (m) => m._id == parent.parent_id )[0].name }` : ""))]),_c('AksesCheckbox',{attrs:{"checked":_vm.allLevel.filter(
                                (m) => m.parent_id == parent._id && m.selected
                              ).length ==
                              _vm.allLevel.filter(
                                (m) => m.parent_id == parent._id
                              ).length,"text":"Semua","css":"my-4 mx-4"},on:{"onChange":(event) =>
                                _vm.checkFromParentId(event.state, parent._id)}})],1),_vm._l((_vm.allLevel.filter(
                            (x) => x.parent_id == parent._id
                          )),function(e){return _c('AksesCheckbox',{key:e._id,attrs:{"item":e,"text":e.name,"css":"my-4 mx-4"},on:{"onChange":_vm.onChangeItem},model:{value:(e.selected),callback:function ($$v) {_vm.$set(e, "selected", $$v)},expression:"e.selected"}})})]:_vm._e()]})]:_vm._l((_vm.allLevel.filter(
                        (x) => x.level == levelNumber
                      )),function(e){return _c('AksesCheckbox',{key:e._id,attrs:{"item":e,"text":e.name,"css":"my-4 mx-4"},on:{"onChange":_vm.onChangeItem},model:{value:(e.selected),callback:function ($$v) {_vm.$set(e, "selected", $$v)},expression:"e.selected"}})})],2)])]:_vm._e()],2)}),0),_vm._t("bottom-actions")]:_c('div',{staticClass:"d-flex justify-content-center mb-5 align-items-center",staticStyle:{"gap":"1rem"}},[_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}),_vm._v(" sedang memuat hak akses... ")],1)],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Hak Akses ")])])])
}]

export { render, staticRenderFns }