<template>
  <div class="row my-5">
    <template v-if="items.length > 0">
      <div
        class="col-sm-2 col-md-6 col-lg-4 position-relative my-3"
        v-for="item in items"
        :key="item._id"
      >
        <div
          class="card rounded py-2 px-4 card-folder"
          role="button"
          @click="open(item)"
        >
          <div class="d-flex justify-content-between mb-3">
            <i class="fa fa-folder fa-xl" v-if="item.type == 'folder'"></i>
            <i class="fa fa-file fa-xl" v-else></i>
          </div>
          <span v-if="item.type == 'folder'"> {{ item.name }}</span>
          <span v-else>{{
            item.name
              ? `${item.name.replaceAll(`.${item.ext}`, "")}.${item.ext}`
              : ""
          }}</span>
        </div>
        <div
          class="position-absolute"
          style="right: 5%; top: 2%"
          v-if="!isEmployee"
        >
          <b-dropdown
            variant="light"
            toggle-class="custom-v-dropdown btn btn-hover-light-primary btn-icon bg-transparent border-0"
            no-caret
            right
            no-flip
          >
            <template v-slot:button-content>
              <i class="ki ki-bold-more-ver"></i>
            </template>
            <!--begin::Navigation-->
            <b-dropdown-text
              tag="div"
              class="navi-item mb-3 mt-1"
              @click="open(item)"
            >
              <span class="navi-link cursor-pointer">
                <span class="navi-text">Buka</span>
              </span>
            </b-dropdown-text>
            <b-dropdown-text
              v-if="!isEmployee"
              tag="div"
              class="navi-item mb-3"
              @click="$emit('edit', item)"
            >
              <span class="navi-link cursor-pointer">
                <span class="navi-text">{{
                  item.type == "file" ? "Edit" : "Edit"
                }}</span>
              </span>
            </b-dropdown-text>
            <b-dropdown-text
              v-if="!isEmployee && item.type == 'file'"
              tag="div"
              class="navi-item mb-3"
              @click="$emit('archive', item)"
            >
              <span class="navi-link cursor-pointer">
                <span class="navi-text">{{
                  !isArchive ? "Arsipkan" : "Pulihkan"
                }}</span>
              </span>
            </b-dropdown-text>
            <b-dropdown-text
              v-if="!isEmployee"
              tag="div"
              class="navi-item"
              @click="$emit('delete', item)"
            >
              <span class="navi-link cursor-pointer">
                <span class="navi-text text-danger">Hapus</span>
              </span>
            </b-dropdown-text>
          </b-dropdown>
        </div>
      </div>
    </template>
    <div
      v-else
      class="d-flex justify-content-center align-items-center h-50 w-100"
    >
      <div class="text-center">
        <i class="fa fa-file fa-xl mb-3"></i>
        <h3>Tidak Ada File</h3>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SET_SELECTED_FOLDER,
  OPEN_PREVIEW_PDF,
} from "@/core/services/store/file.module";
export default {
  name: "GridView",
  props: ["items"],
  computed: {
    isEmployee() {
      return (
        this.$store.getters.currentUser.role == "student" ||
        this.$store.getters.currentUser.role == "staff"
      );
    },
    isArchive() {
      return this.$route.query.type && this.$route.query.type == "archive";
    },
  },
  methods: {
    open(item) {
      if (item.type == "folder")
        this.$store.dispatch(SET_SELECTED_FOLDER, item);
      else {
        this.$store.dispatch(OPEN_PREVIEW_PDF, item);
      }
    },
  },
};
</script>

<style scoped>
.fa-xl {
  font-size: 5rem;
}

.card-folder:hover {
  background-color: #f2f2f2;
}

.row-eq-height {
  display: flex;
}
</style>
