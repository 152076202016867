<template>
  <div :class="['card rounded', 'h-100']">
    <div class="card-header" v-if="!isEmployee && !isArchive">
      <b-button variant="info" class="w-100" @click="$emit('add-folder')"
        ><i class="fa fa-folder mr-2"></i> Tambah Folder</b-button
      >

      <!-- <b-dropdown variant="info" block menu-class="w-100">
        <template #button-content> Tambah Baru </template>
        <b-dropdown-item href="#" @click="$emit('add-document')"
          ><i class="fa fa-file mr-3"></i>Tambah File</b-dropdown-item
        >
        <b-dropdown-item href="#" @click="$emit('add-folder')"
          ><i class="fa fa-folder mr-2"></i>Tambah Folder</b-dropdown-item
        >
      </b-dropdown> -->
    </div>
    <div class="px-10 pt-2">
      <template v-if="items.length > 0">
        <div
          v-for="(item, index) in items.filter((x) => x.type == 'folder')"
          :key="index"
        >
          <div
            role="button"
            @click="open(item)"
            class="d-flex justify-content-start align-items-center py-3"
            block
            v-b-toggle="'accordion-' + item._id"
            style="gap: 1rem"
          >
            <i class="fa fa-folder fa-xl"></i>
            <span
              :class="[
                'ml-1',
                currentSelectedFolder._id == item._id ? 'text-warning' : '',
              ]"
            >
              {{ item.name }}
            </span>
          </div>
          <b-collapse
            v-if="item.child !== undefined"
            :id="'accordion-' + item._id"
            :accordion="'my-accordion-1' + item._id"
            role="tabpanel"
            class="ml-5"
          >
            <ChildFolder :item="item" />
          </b-collapse>
        </div>
      </template>
      <div
        v-else
        class="d-flex justify-content-center align-items-center mt-10 w-100"
      >
        <div class="text-center" v-if="!currentIsLoading">
          <i class="fa fa-file fa-xl-no-folder mb-3"></i>
          <h5 class="mt-5">Tidak Ada Folder</h5>
        </div>
        <div class="text-center" v-else>
          <b-spinner class="align-middle"></b-spinner>
          <h5 class="mt-10">Memuat Folder</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildFolder from "@/view/pages/file_management/components/ChildFolder.vue";
import { fileManagementMixin } from "@/view/pages/file_management/mixin/fileManagementMixin";

import { SET_SELECTED_FOLDER } from "@/core/services/store/file.module";
import { mapGetters } from "vuex";

export default {
  name: "Folder",
  props: ["items"],
  components: {
    ChildFolder,
  },
  mixins: [fileManagementMixin],
  computed: {
    ...mapGetters(["currentSelectedFolder", "currentIsLoading"]),
    isEmployee() {
      return (
        this.$store.getters.currentUser.role == "student" ||
        this.$store.getters.currentUser.role == "staff"
      );
    },
    isArchive() {
      return this.$route.query.type && this.$route.query.type == "archive";
    },
  },
  methods: {
    open(item) {
      this.openFolder(item.nama);
      this.$store.dispatch(SET_SELECTED_FOLDER, item);
    },
  },
};
</script>

<style scoped>
.fa-xl-no-folder {
  font-size: 3rem !important;
}
</style>
