<template>
  <div>
    <div class="card card-custom card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Hak Akses
          </span>
        </h3>
      </div>
      <!-- <b-card-header header-tag="header" class="p-1" role="tab">
              <div class="d-flex justify-content-between">
                  <div class="align-self-center">
                      <span class="text-dark font-weight-bold text-hover-primary font-size-lg mr-2">Hak Akses</span>
                  </div>
              </div>
          </b-card-header> -->
      <b-card-body>
        <template v-if="!isLoading">
          <div class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-12 mb-3"
              v-for="levelNumber in level"
              :key="levelNumber"
            >
              <template
                v-if="allLevel.filter((x) => x.level == levelNumber).length > 0"
              >
                <span
                  class="text-dark font-weight-bold text-hover-primary font-size-sm mr-2"
                  >{{ levelName[levelNumber - 1] }}</span
                >
                <b-card no-body>
                  <!-- <AksesCheckbox text="Pilih Semua" css="my-4 mx-4" /> -->
                  <div>
                    <template v-if="levelNumber > 1">
                      <template
                        v-for="parent in allLevel.filter(
                          (x) => x.level == levelNumber - 1
                        )"
                      >
                        <template
                          v-if="
                            allLevel.filter((x) => x.parent_id == parent._id)
                              .length > 0
                          "
                        >
                          <div
                            class="d-flex justify-content-between align-items-center bg-secondary px-3 py-1"
                            :key="parent._id"
                          >
                            <span class="text-dark font-weight-bold">
                              {{ parent.name }}
                              {{
                                parent.level == 4
                                  ? ` - ${
                                      allLevel.filter(
                                        (m) => m._id == parent.parent_id
                                      )[0].name
                                    }`
                                  : ""
                              }}</span
                            >

                            <AksesCheckbox
                              @onChange="
                                (event) =>
                                  checkFromParentId(event.state, parent._id)
                              "
                              :checked="
                                allLevel.filter(
                                  (m) => m.parent_id == parent._id && m.selected
                                ).length ==
                                allLevel.filter(
                                  (m) => m.parent_id == parent._id
                                ).length
                              "
                              text="Semua"
                              css="my-4 mx-4"
                            />
                          </div>
                          <AksesCheckbox
                            :item="e"
                            @onChange="onChangeItem"
                            v-model="e.selected"
                            :text="e.name"
                            css="my-4 mx-4"
                            v-for="e in allLevel.filter(
                              (x) => x.parent_id == parent._id
                            )"
                            :key="e._id"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <AksesCheckbox
                        :item="e"
                        @onChange="onChangeItem"
                        v-model="e.selected"
                        :text="e.name"
                        css="my-4 mx-4"
                        v-for="e in allLevel.filter(
                          (x) => x.level == levelNumber
                        )"
                        :key="e._id"
                      />
                    </template>
                  </div>
                </b-card>
              </template>
            </div>
          </div>
          <slot name="bottom-actions"></slot>
        </template>
        <div
          class="d-flex justify-content-center mb-5 align-items-center"
          style="gap: 1rem"
          v-else
        >
          <b-spinner small label="Small Spinner"></b-spinner> sedang memuat hak
          akses...
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { GET_LEVEL_ACCESS } from "@/core/services/store/level.module";

import AksesCheckbox from "./components/AksesCheckbox.vue";

export default {
  name: "PilihHakAkses",
  props: {
    initValue: Array,
  },
  components: {
    AksesCheckbox,
  },
  data() {
    return {
      isLoading: false,
      allLevel: [],
      level: [1, 2, 3, 4, 5],
      levelName: ["Direktorat", "Divisi", "Departemen", "Supervisor", "Staff"],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    allLevel: {
      handler: function (newVal, oldValue) {
        const selected = newVal.filter((x) => x.selected).map((e) => e._id);
        this.$emit("selected", selected);
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_LEVEL_ACCESS)
        .then((data) => {
          this.isLoading = false;

          this.allLevel = [];
          data.data.map((e) => this.collectedAllLevel(e));

          if (this.initValue !== undefined) {
            const _idSelected = JSON.parse(JSON.stringify(this.initValue));

            if (_idSelected) {
              this.allLevel.map((x) => {
                var isExist = false;

                _idSelected.map((m) => {
                  if (m == x._id) isExist = true;
                });

                if (isExist) {
                  x.selected = true;
                }
              });
            }
          }
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast(response?.data?.message ?? "terjadi kesalahan", {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    collectedAllLevel(item) {
      var selected = false;

      this.allLevel.push({
        _id: item._id,
        name: item.name,
        level: item.level,
        parent_id: item.parent_id,
        selected: selected,
      });

      if (item.child !== undefined) {
        item.child.map((e) => this.collectedAllLevel(e));
        return;
      }
    },
    changeChildCheckbox(item, state) {
      this.allLevel
        .filter((x) => x.level == item.level + 1 && x.parent_id == item._id)
        .map((e) => {
          this.changeChildCheckbox(e, state);
          e.selected = state;
          return {
            ...e,
          };
        });
    },
    changeParentCheckBox(item, state) {
      this.allLevel
        .filter((x) => x.level == item.level - 1 && item.parent_id == x._id)
        .map((e) => {
          this.changeParentCheckBox(e, state);
          e.selected = state;
          return {
            ...e,
          };
        });
    },
    onChangeItem(item) {
      this.changeChildCheckbox(item.item, item.state);
      //if (item.state) {
      //this.changeParentCheckBox(item.item, item.state);
      // }
    },

    checkFromParentId(state, parent_id) {
      const selectedOnGroup = this.allLevel.filter(
        (x) => x.parent_id == parent_id && x.selected
      ).length;

      const maxItemOnGroup = this.allLevel.filter(
        (x) => x.parent_id == parent_id
      ).length;

      this.allLevel
        .filter((x) => x.parent_id == parent_id)
        .map((x) => {
          if (selectedOnGroup == maxItemOnGroup - 1 && !state) {
            return x;
          }
          x.selected = state;
          return x;
        });

      this.allLevel
        .filter((x) => x.parent_id == parent_id)
        .forEach((x) => {
          this.changeChildCheckbox(x, x.selected);
        });
    },
  },
};
</script>
