<template>
  <b-modal
    ref="form-folder-modal"
    id="form-folder-modal"
    size="md"
    :title="`${form._id ? 'Edit' : 'Tambah'} Folder`"
    centered
    @hide="hideModal"
  >
    <validation-observer ref="formFolder">
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Induk Folder"
          label-for="input-1"
        >
          <validation-provider #default="{ errors }" name="Induk Folder">
            <b-form-input
              id="input-1"
              type="text"
              placeholder="Induk Folder"
              v-model="form.parent.name"
              disabled
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Nama Folder"
          label-for="input-1"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Folder"
            rules="required"
          >
            <b-form-input
              :state="errors.length > 0 ? false : null"
              id="input-1"
              type="text"
              v-model="form.name"
              placeholder="Nama Folder"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="outline-secondary" @click="cancel()"
        >Batalkan
      </b-button>
      <b-button size="sm" variant="success" @click="submitForm(ok)">
        Simpan Data
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  CREATE_FOLDER,
  GET_FOLDER,
  UPDATE_FOLDER,
  SET_SELECTED_FOLDER,
} from "@/core/services/store/file.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "FormFolderModal",
  data() {
    return {
      form: {
        name: null,
        parent: {
          parent_id: null,
          name: "Dokumen Grafindo",
        },
        type: "folder",
        access: [],
      },
    };
  },
  computed: {
    ...mapGetters(["currentSelectedFolder", "currentFiles"]),
  },
  mounted() {},
  methods: {
    toggleModal(item) {
      if (item !== undefined) {
        this.form = item;

        if (item.parent_id) {
          this.setFolderParent(
            this.currentSelectedFolder._id,
            this.currentFiles
          );
        } else {
          this.form.parent = {
            name: "Dokumen Grafindo",
            parent_id: null,
          };
        }
      } else {
        if (this.currentSelectedFolder._id != null) {
          this.setFolderParent(
            this.currentSelectedFolder._id,
            this.currentFiles
          );
        } else {
          this.form.parent = {
            name: "Dokumen Grafindo",
            parent_id: null,
          };
        }
      }

      this.$refs["form-folder-modal"].toggle();
    },
    hideModal() {
      this.form = {
        name: null,
        parent: {
          parent_id: null,
          name: "Dokumen Grafindo",
        },
        type: "folder",
        access: [],
      };
    },
    submitForm(close) {
      this.$refs.formFolder.validate().then((success) => {
        if (success) {
          var _data = this.form;
          _data.parent_id = this.form.parent.parent_id;

          this.isLoading = true;
          this.$store
            .dispatch(_data._id ? UPDATE_FOLDER : CREATE_FOLDER, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${_data._id ? "ubah" : "buat"} folder`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              close();
              this.getFolder();
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.$bvToast.toast(error.data.message, {
                title: `Gagal Membuat Folder`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getFolder() {
      this.$store
        .dispatch(GET_FOLDER)
        .then((response) => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$bvToast.toast(error.response, {
            title: `Gagal memuat Folder`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setFolderParent(id, _data) {
      _data.map((x) => {
        if (x._id == id) {
          this.form.parent = {
            parent_id: x._id,
            name: x.name,
          };
          return;
        }
        if (x.child) this.setFolderParent(id, x.child);
      });
    },
  },
};
</script>
