<template>
  <div
    :class="['d-flex align-items-center', css]"
    @click="checkBoxClick"
    role="button"
  >
    <div
      class="border rounded custom-checkbox mr-2 d-flex align-items-center"
      :class="{
        'bg-info': isSelected,
        'bg-white': !isSelected,
      }"
    >
      <i
        v-if="isSelected"
        class="fa fa-check mx-auto fa-sm"
        :class="{
          'text-white': isSelected,
        }"
      ></i>
      <i
        v-if="!isSelected"
        class="fa fa-minus mx-auto fa-sm"
        :class="{
          'text-white': isSelected,
        }"
      ></i>
    </div>
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "AksesCheckbox",
  props: {
    text: String,
    css: String,
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    checked: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: "dark",
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    checkBoxClick(item) {
      this.selected = !this.selected;

      this.$emit("input", this.selected);

      if (this.item != null || this.checked != null) {
        this.$emit("onChange", {
          item: this.item,
          state: this.selected,
        });
      }
    },
  },
  watch: {
    value(newVal) {
      this.selected = newVal;
    },
    checked(newVal) {
      if (newVal != null) {
        this.selected = newVal;
        this.$emit("onChange", {
          item: null,
          state: this.selected,
        });
      }
    },
  },
  computed: {
    isSelected() {
      return this.selected;
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  height: 1.85rem;
  width: 1.85rem;
}
</style>
